import { FunctionComponent} from "react";
import Footer from "../../components/Footer";
import HeaderCarousel from "../../components/HeaderCarousel";
import InfoPane from "../../components/InfoPane";
import MissionStatement from "../../components/MissionStatement";
import PromoRow from "../../components/PromoRow";

const HomePage: FunctionComponent = () => {
    return (
        <div className="page-content">
            <HeaderCarousel />
            <InfoPane />
            <hr></hr>
            <MissionStatement />
            <PromoRow />
            <Footer />
        </div>
    )
}

export default HomePage;