import { FunctionComponent} from "react";
import { Link } from "react-router-dom";

const PressReleaseContent3: FunctionComponent = () => {
    return (
        <div className="press-release-content">
        <div className="indent">

            <h5 className="back"><Link to="/newsroom">Back to Newsroom</Link></h5>

            <h2>Global Task Force on Pandemic Response Provides Critical Support to Southeast Asia’s Diagnostic Capacity in its Battle Against COVID-19</h2>

            <p>WASHINGTON, D.C. – The Global Task Force on Pandemic Response (GTF), a public-private partnership of major U.S. companies organized by the U.S. Chamber of Commerce with support from Business Roundtable, announced today that it will help strengthen Indonesia and Southeast Asia’s diagnostic capacity as they continue to battle against major COVID-19 outbreaks. This follows the GTF’s  earlier announcement made in August of its commitment to mobilize to support pandemic relief in Indonesia and Southeast Asia.</p>
            <p>The GTF will assist Indonesia by procuring PCR test equipment, rapid antigen tests that can easily be deployed across the country, and genomic sequencing equipment to help identify COVID-19 variants.</p>
            <p>This work is possible with generous support from Microsoft, Accenture and Deloitte with more companies expected to contribute to a $10 million fund for diagnostics equipment in Southeast Asia.</p>
            <p>The GTF is in discussions with manufacturers to confirm adequate supply for the region at competitive prices. The Indonesian government officials, local NGOs, and U.S. government representatives are coordinating to ensure solutions that provide immediate impact.</p>
            <p>Testing, oxygen supplies, and hospital capacity are insufficient across Southeast Asia, which is experiencing a major COVID-19 outbreak; in August, Indonesia was the global epicenter of the pandemic, which continues to surge across Southeast Asia at rates previously unseen.</p>

            <p>The GTF is working in the region in partnership with the US-ASEAN Business Council (USABC), the American Indonesian Chamber of Commerce (AICC) and the American Chamber of Commerce in Indonesia (AmCham Indonesia).</p>

            <p>The U.S. Chamber of Commerce Foundation also offers a COVID-19 Business Response portal, a platform for U.S. businesses to offer essential in-kind products and services needed outside of the United States.  Visit the portal to review a list of critically needed supplies or offer in-kind donations to support Indonesia’s pandemic response.</p>

            <p>For more information on the Global Task Force’s work, please visit <Link to="/home">pandemictaskforce.org</Link>.</p>
        </div>
    </div>
    )
}

export default PressReleaseContent3;