import {FunctionComponent} from "react";
import Footer from "../../components/Footer";
import HeaderNav from "../../components/HeaderNav";
import PressReleaseContent from "../../components/PressReleaseContent";

const PressRelease: FunctionComponent = () => {
    return (
        <div className="page-content">
            <HeaderNav
                lightMode={true} />
            <PressReleaseContent />
            <Footer />
        </div>
    )
}

export default PressRelease;