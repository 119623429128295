import { FunctionComponent} from "react";
import { Link } from "react-router-dom";

const PressReleaseContent2: FunctionComponent = () => {
    return (
        <div className="press-release-content">
        <div className="indent">

            <h5 className="back"><Link to="/newsroom">Back to Newsroom</Link></h5>

            <h2>Global Task Force on Pandemic Response Mobilizes Pandemic Relief in Indonesia and Southeast Asia, with Pledges of Aid from U.S. Businesses</h2>

            <p>WASHINGTON, D.C. – The Global Task Force on Pandemic Response (GTF) — a public-private partnership of major U.S. companies organized by the U.S. Chamber of Commerce with support from Business Roundtable — today announced plans to mobilize in support COVID-19 pandemic relief and response in Indonesia and Southeast Asia. </p>

            <p>In partnership with the US-ASEAN Business Council (USABC), the American Indonesian Chamber of Commerce (AICC) and the American Chamber of Commerce in Indonesia (AmCham Indonesia), and working in cooperation with the Government of Indonesia, Global Task Force members will provide critically needed medical supplies and protective equipment in areas of highest need. Southeast Asia is experiencing a major outbreak, and Indonesia now accounts for approximately one in five fatalities globally as it grapples with shortages of oxygen and hospital capacity amid a surge in cases.</p>

            <p>“We have been in close touch with both the Indonesian and U.S. governments to identify critical needs and assess where private sector support can be most impactful,” said Charles Freeman, Senior Vice President for Asia at the U.S. Chamber of Commerce. “American companies are ready to step up and provide oxygen supplies and equipment, COVID treatments and other assistance.”</p>
            
            <p>“The situation on the ground in Indonesia is dire. As the fourth largest nation in the world and the largest economy in Southeast Asia, Indonesia is a significant market for U.S. industry,” said Alexander Feldman, President &amp; CEO of the US-ASEAN Business Council. “In these challenging times, the American business community stands ready to lend its support, and we are honored to work with the Global Task Force and the Government of Indonesia to ensure the Indonesian people get the medical supplies and assistance they need.”</p>

            <p>The announcement came during a meeting between Indonesian Minister for Foreign Affairs Retno Marsudi and senior executives from nearly two dozen U.S. companies, including Deloitte, Freeport-McMoRan and Microsoft, who will lead the Global Task Force Indonesia mobilization effort. During the event, several companies announced additional commitments to support Indonesia’s pandemic response:

                <ul>
                    <li>Deloitte will leverage its experience in India on Sanjeevani Pariyojana (‘the life project’) to expand hospital wards and triage medical care for COVID patients in Indonesia. </li>
                    <li>Freeport-McMoRan, through its subsidiary PT Freeport Indonesia (PTFI), is producing and distributing medical-grade oxygen after the successful conversion of an industrial gas generating plant. PTFI is prepared to convert a second industrial gas generating plant to produce medical-grade oxygen if needed. PTFI also recently donated over 30 oxygen concentrators to local hospitals.</li>
                    <li>Google announced a Google.org grant of $1 million to the International Federation of Red Cross and Red Crescent Societies (IFRC) to support Indonesian Red Cross Society work to supply oxygen cylinders, oxygen concentrators, ventilators, as well as diagnostic equipment. Google has also provided ad grants to the Indonesian Government to disseminate public health messaging.</li>
                    <li>Microsoft will invest $1 million in programs to increase Indonesia’s access to critical medical supplies, including oxygen supplies and equipment, supporting both current demand and future needs. </li>
                    <li>The Bill &amp; Melinda Gates Foundation will commit $1.25M to address critical needs in Indonesia, including support for oxygen supply and delivery systems to address current and future COVID response and healthcare needs. </li>
                </ul>
            </p>

            <p>The Global Task Force’s pandemic response efforts in Indonesia follow a <Link to="/impact">major mobilization in India</Link>, which leveraged private sector contributions to deliver over 900 ventilators and 25,000 oxygen concentrators to cities and states across the country. The U.S. Chamber of Commerce Foundation also launched a COVID-19 Business Response portal, a platform for U.S. businesses to offer essential in-kind products and services needed outside of the United States. <a href="https://www.uschamberfoundation.org/blog/post/addressing-covid-19-crisis-indonesia" target="_blank">Visit the portal</a> to review a list of critically needed supplies or offer in-kind donations to support Indonesia’s pandemic response. </p>
            <p>For more information on the Global Task Force’s work, please visit <Link to="/home">pandemictaskforce.org</Link>.</p>

        </div>
    </div>
    )
}

export default PressReleaseContent2;