export type Color =
  | "#E3364E" // Red
  | "#002D5A" // blue

export const RED: Color = "#E3364E";
export const BLUE: Color = "#002D5A";

export type ButtonClass = 
  | "primary_blue"
  | "white";


export const BUTTON_PRIMARY_BLUE: ButtonClass = 'primary_blue';
export const BUTTON_WHITE: ButtonClass = 'white';


export type LogoType = 
  | "GTF_logo_compact.png"
  | "GTF_logo_compact_white.png"
  | "GTF_logo_compact_black.png"
  | "GTF_logo_long.png"
  | "GTF_logo_long_white.png"
  | "GTF_logo_long_black.png"
  | "GTF_Logo_V_Color_Invert.png";

export const LOGO_WHITE: LogoType = "GTF_logo_compact_white.png";
export const LOGO_COLOR: LogoType = "GTF_logo_compact.png";
export const LOGO_BLACK: LogoType = "GTF_logo_compact_black.png";
export const LOGO_WHITE_FLAT: LogoType = "GTF_logo_long_white.png";
export const LOGO_COLOR_FLAT: LogoType = "GTF_logo_long.png";
export const LOGO_BLACK_FLAT: LogoType = "GTF_logo_long_black.png";
export const LOGO_COLOR_INVERT: LogoType = "GTF_Logo_V_Color_Invert.png";