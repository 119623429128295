import {FunctionComponent} from "react";
import { Logo } from "../../atoms";
import { LOGO_COLOR_FLAT } from "../../atoms/colors";

const Footer: FunctionComponent = () => {
    return (
        <footer>
            <Logo
                logoType={LOGO_COLOR_FLAT} />
            Copyright &copy; 2022.
        </footer>
    )
}

export default Footer;