import { FunctionComponent} from "react";
import { Link } from "react-router-dom";

const MissionStatement: FunctionComponent = () => {
    return (
        <div className="mission-statement">
            <h2>We Commit to Meaningful Aid</h2>
            <div className="copy">
                <div className="item">
                    <h4>Identify and Prioritize Needs</h4>
                    <p>Work with governmental partners to identify and prioritize needs of local communities and coordinate collective action (<Link to="/impact">Impact</Link>), and also facilitate in-kind donations from the private sector (<a href="https://usccfdonationportal.communityos.org/" target="_blank">Donation Portal</a>)</p>
                </div>

                <div className="item">
                    <h4>Share Critical Information</h4>
                    <p>Help businesses better support their employees by increasing sharing of critical information (<Link to="/pdf/GTF_FactSheet_CHRO.pdf" target="_blank">CHRO–human resources assistance</Link>)</p>
                </div>
                
                <div className="item">
                    <h4>Connect Business and Government</h4>
                    <p>Provide the business community more direct and frequent interfaces with governments so they can stay better informed and have a channel to advocate for their needs (<a href="mailto:JMatton@USChamber.com" title="JMatton@USChamber.com">Learn More</a>)</p>
                </div>
            </div>

        </div>
    )
}

export default MissionStatement;