import {FunctionComponent} from "react";
import { Button, Logo } from "../../atoms";
import { BUTTON_PRIMARY_BLUE } from "../../atoms/colors";
import { Link } from "react-router-dom";

const InfoPane: FunctionComponent = () => {
    return (
        <div className="info-pane">
            <div className="main">
                <h2>Who We Are</h2>
                
                <p>The Global Task Force on Pandemic Response (GTF) is a public-private partnership that provides a unified platform for businesses to mobilize and deliver resources to assist COVID-19 efforts amid rising case counts across the globe.</p> 
                <p>This effort is organized by the U.S. Chamber of Commerce and supported by Business Roundtable, working with country business councils, AmChams, as well as other relevant stakeholders.</p>
                <p>If you would like to support the GTF, please visit the <Link to="/getinvolved">Get Involved</Link> page to learn more.</p>
            </div>
            {/* <div className="left">
                <Logo />
                <Button
                    displayText="Why we help"
                    buttonClass={BUTTON_PRIMARY_BLUE}
                    target="/globalpandemic" />
            </div>
            <div className="main">
                <p>The <strong>Global Task Force on Pandemic Response</strong> is a public-private partnership launched to provide a unified platform for businesses to mobilize and deliver resources to assist COVID-19 efforts in areas of the highest need around the world.</p>
                <p>This effort is organized by the U.S. Chamber of Commerce and supported by Business Roundtable, working with the Chamber’s U.S.-India Business Council and the U.S.-India Strategic Partnership Forum on India mobilization.</p>
                <p>The Global Task Force will also serve as an ongoing point of contact between the business community and U.S. government in coordinating emergency pandemic assistance overseas.</p>
                
                <Button
                    displayText="How we Help"
                    buttonClass={BUTTON_PRIMARY_BLUE}
                    target="/impact" />
            </div> */}
        </div>
    )
}

export default InfoPane;