import {FunctionComponent, useState} from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import HeaderNav from "../HeaderNav";
import { Link } from "react-router-dom";
import { Button } from "../../atoms";
import { LOGO_COLOR, BUTTON_PRIMARY_BLUE } from "../../atoms/colors";

const HeaderCarousel:FunctionComponent = () => {

    const slides = [
        {
            header: 'Global task force on',
            subhead: 'Pandemic Response',
            image: '/photos/hero-image.jpg',
            info: 'Providing critical life-saving support to countries hard-hit by the COVID-19 pandemic.',
            cta1: 'Why we help',
            cta1link: '/globalpandemic',
            cta2: 'How we help',
            cta2link: '/impact'
        }
    ];
    const [currentSlideNum, setCurrentSlideNum] = useState(0);

    const getFormmattedSlideCount = () => {
        if(slides.length < 10){
            return "0" + slides.length;
        }else{
            return slides.length;
        }
    }

    const nextSlide = () =>{
        if(currentSlideNum === (slides.length - 1)){
            setCurrentSlideNum(0);
        }else{
            setCurrentSlideNum(currentSlideNum + 1);
        }
    }

    const prevSlide = () =>{
        if(currentSlideNum === 0){
            setCurrentSlideNum(slides.length - 1);
        }else{
            setCurrentSlideNum(currentSlideNum - 1);
        }
    }

    const getSliderCounter = () => {
        if(slides.length === 1){
            return null;
        }
        return (
            <div className="slider-status">
                <div className="start">01</div>
                {slides.map((currentSlide, index) => {
                    if(index === currentSlideNum){
                        return (
                            <div key={index} className="item active"></div>
                        )
                    }else{
                        return (
                            <div key={index} className="item"></div>
                        )
                    }
                })}
                <div className="end">{getFormmattedSlideCount()}</div>
            </div>
        );
    }

    return (
        <>
        <HeaderNav
            logoType={LOGO_COLOR} />
        <div className="header-carousel">
            <div className="carousel-container">
                <Carousel
                    swipeable={true}
                    showArrows={false}
                    showThumbs={false}
                    showStatus={false}
                    showIndicators={false}
                    autoPlay={slides.length > 1 ? true : false}
                    interval={5000}
                    infiniteLoop={true}
                    transitionTime={500}
                    selectedItem={currentSlideNum}
                    onChange={(newIndex) => setCurrentSlideNum(newIndex)}>
                    {
                        slides.map((currentSlide, index) => {
                            return (
                                <div
                                    key={index}
                                    className="content"
                                    style={{
                                        background: "url(" + currentSlide.image +")"
                                    }}
                                    >
                                    <div className="info">
                                        <div className="lead">
                                            <h1>{currentSlide.header}</h1>
                                            <h2>{currentSlide.subhead}</h2>
                                            <p>{currentSlide.info}</p>
                                            {/* {currentSlide.cta1 !== '' && currentSlide.cta1link !== '' ? (
                                                <Button
                                                    displayText={currentSlide.cta1}
                                                    buttonClass={BUTTON_PRIMARY_BLUE}
                                                    target={currentSlide.cta1link} />
                                            ) : null} */}
                                            {currentSlide.cta2 !== '' && currentSlide.cta2link !== '' ? (
                                                <Button
                                                    displayText={currentSlide.cta2}
                                                    buttonClass={BUTTON_PRIMARY_BLUE}
                                                    target={currentSlide.cta2link} />
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </Carousel>
                {/* <div className="controls">
                   <div className="pane">
                       <div className="learn-more"><Link to="/about">Learn More</Link></div>
                        {
                            slides.length > 1 ?
                                <div className="slider-controls">
                                    <div className="left" onClick={prevSlide}></div>
                                    <div className="right" onClick={nextSlide}></div>
                                </div>
                            :
                                null
                        }
                        {getSliderCounter()}
                   </div>
                </div> */}
            </div>
        </div>
        </>
    )
}

export default HeaderCarousel;