import { FunctionComponent } from "react";
import Footer from "../../components/Footer";
import HeaderNav from "../../components/HeaderNav";
import Header from "../../components/Header";
import NewsList from "../../components/NewsList";

const Newsroom: FunctionComponent = () => {

    return (
        <div className="page-content">
            <HeaderNav lightMode={true} />
            <Header title="Newsroom" headerImg="newsroom.jpg"/>
            <NewsList />
            <Footer />
        </div>
    )
}

export default Newsroom;