import { FunctionComponent} from "react";
import { Link } from "react-router-dom";

const PressReleaseContent: FunctionComponent = () => {
    return (
        <div className="press-release-content">
        <div className="indent">

            <div className="back"><Link to="/newsroom">Back to Newsroom</Link></div>

            <h2>Global Task Force on Pandemic Response Launched by Leading Companies and Business Associations to Address Urgent COVID-19 Surges</h2>

            <p><em>Public-private partnership will provide immediate assistance to India and will assist in coordinating relief to respond to COVID-19 surges.</em></p>
            <p>
                The Task Force will coordinate a coalition of corporations, non-profits and individual efforts to organize relief where it is needed most:
                <ul>
                    <li>1,000 Medtronic ventilators to be delivered to India starting May 5</li>
                    <li>25,000 oxygen concentrators scheduled to be delivered by end of May, with thousands already delivered</li>
                    <li>Network of Human Resources leaders offering practical guidance on supporting employees in India </li>
                </ul>
            </p>

            <h4>FOR IMMEDIATE RELEASE</h4>

            <h4>May 5, 2021</h4>

            <p>WASHINGTON, DC – The Global Task Force on Pandemic Response, a newly formed U.S.-based public-private partnership organized by the U.S. Chamber of Commerce and supported by Business Roundtable, announced today that it is working with the Chamber’s U.S.-India Business Council and the U.S.-India Strategic Partnership Forum to take three immediate actions to help address the COVID-19 surge in India:  
                <ul>
                    <li>Sourcing, shipping and delivering 1,000 Puritan Bennett™ ventilators desperately needed by healthcare facilities across India. The first ventilators procured by the U.S. Chamber of Commerce Foundation will arrive in India today, with all remaining ventilators expected to arrive by June 3. Medtronic will manufacture the ventilators and handle end-to-end shipping, installation and <a href="http://www.ventilators.com" target="_blank" rel="noreferrer">ongoing and virtual training</a>. (<a href="/pdf/GTF_FactSheet_Ventilators.pdf" target="_blank" rel="noreferrer">Fact Sheet</a>)</li> 

                    <li>Delivering 25,000 oxygen concentrators to India by the end of May, with transportation support from FedEx. (<a href="/pdf/GTF_FactSheet_Oxygen.pdf" target="_blank" rel="noreferrer">Fact Sheet</a>)</li>

                    <li>Creating the Chief Human Resources Officer (CHRO) India Action Group to provide ideas and practical information to CHROs to help their people in India. (<a href="/pdf/GTF_FactSheet_CHRO.pdf" target="_blank" rel="noreferrer">Fact Sheet</a>)  </li>
                </ul>
            </p>

            <p>The Global Task Force on Pandemic Response was launched to provide a unified platform for businesses to mobilize and deliver resources to assist COVID-19 efforts in areas of the highest need around the world.</p>

            <p>Initial efforts will focus on the pressing need for support in India, with more than 400,000 cases reported on May 1 alone. Through its Steering Committee, the Task Force will work to concentrate efforts where corporate support will be most beneficial, with additional countries to be determined in consultation with the U.S. government.  </p>


            <p>Steering Committee members, who provide leadership and guidance on the Task Force’s activities, include Julie Sweet, CEO, Accenture; Andy Jassy, CEO, Amazon Web Services; Gail McGovern, President and CEO, American Red Cross; Tim Cook, CEO, Apple; Brian Moynihan, Chairman of the Board and CEO, Bank of America; Mike Parra, CEO Americas, DHL Express; Jim Fitterling, Chairman and CEO, Dow; Raj Subramaniam, President, Chief Operating Officer, and Director, FedEx; Arvind Krishna, Chairman and CEO, IBM; Alex Gorsky, Chairman of the Board and CEO, Johnson & Johnson; Michael Miebach, CEO, Mastercard; Geoff Martha, Chairman and CEO, Medtronic; Brad Smith, President, Microsoft; Ramon Laguarta, Chairman of the Board and CEO, PepsiCo; Carol Tomé, CEO, UPS; Sanjay Poonen, COO, VMware; and Judith McKenna, President and CEO, Walmart International. </p>

            <p>The Global Task Force is working in close collaboration with U.S. and Indian government officials to share information and coordinate efforts. This includes regular briefings with the Modi and Biden Administrations, U.S. Congress, U.S. State Department and the U.S. Agency for International Development.</p>

            <p>The coalition of leading companies, non-profits and associations that have come together to support these actions include: Accenture, Adobe, Amazon, American Express, Amway, Apple, Applied Materials Foundation, Bank of America, BCG, Citi, David & Carol Van Andel Family Foundation, Dell, Deloitte, Dow, Ernst & Young, Emerson, Facebook, FedEx, Goldman Sachs, IBM, Intel, Johnson & Johnson, John Chambers Foundation, Johnson Controls, JP Morgan Chase & Co, KKR, Lockheed Martin, Mastercard, McCormick & Company, McKinsey & Company, Medtronic, Merck, Microsoft, Nasdaq, Newsweek, PepsiCo, Pfizer, Qualcomm Foundation, Raytheon Technologies, the U.S. Chamber of Commerce Foundation, VIAVI Solutions, VMware, Walmart and Zoom.</p>
            <p>For more information on the Global Task Force’s work, please visit <a href="https://pandemictaskforce.org" target="_blank" rel="noreferrer">pandemictaskforce.org</a>. The website also includes portal for companies to submit donations of in-kind goods and services. </p>
 
            <hr />

            <h4>About the U.S. Chamber of Commerce</h4>

            <p>The U.S. Chamber of Commerce is the world’s largest business organization representing companies of all sizes across every sector of the economy. Our members range from the small businesses and local chambers of commerce that line the Main Streets of America to leading industry associations and large corporations.  </p>

            <p>They all share one thing: They count on the U.S. Chamber to be their voice in Washington, across the country, and around the world. For more than 100 years, we have advocated for pro-business policies that help businesses create jobs and grow our economy.  </p>

            <h4>About Business Roundtable </h4>

            <p>Business Roundtable CEOs lead America’s largest companies, employing 20 million workers. Their companies’ total value, over $20 trillion, accounts for approximately half of the value of all publicly-traded companies in the United States. They spend and invest over $7 trillion a year, helping sustain and grow tens of thousands of communities and millions of medium- and small-sized businesses.</p> 
        </div>
    </div>
    )
}

export default PressReleaseContent;