import { FunctionComponent} from "react";
import { Link } from "react-router-dom";

const ResourceContent: FunctionComponent = () => {
    return (
        <div className="resource-content">
            <div className="indent">
                <h2>Available Factsheets</h2>
            </div>

            <div className="fact-sheets">
                <div className="item">
                    <h3>01</h3>
                    <h4>Task Force Fact Sheet</h4>
                    <a href="/pdf/GTF_FactSheet_GeneralFactSheet.pdf" rel="noreferrer" target="_blank">Learn More</a>
                </div>
                <div className="item">
                    <h3>02</h3>
                    <h4>India Ventilator Fact Sheet</h4>
                    <a href="/pdf/GTF_FactSheet_Ventilators.pdf" rel="noreferrer" target="_blank">Learn More</a>
                </div>
                <div className="item">
                    <h3>03</h3>
                    <h4>India Oxygen Concentrator Fact Sheet</h4>
                    <a href="/pdf/GTF_FactSheet_Oxygen.pdf" rel="noreferrer" target="_blank">Learn More</a>
                </div>
                <div className="item">
                    <h3>04</h3>
                    <h4>Chief Human Resources Officer (CHRO) India Action Group Fact Sheet</h4>
                    <a href="/pdf/GTF_FactSheet_CHRO.pdf" rel="noreferrer" target="_blank">Learn More</a>
                </div>
            </div>

            <div className="indent">
                <h2>Available Sources</h2>
            </div>

            <div className="resources">
                <div className="resource">
                    <div className="item">
                        <h3>01</h3>
                        <h4>COVID-19 Business Response Portal</h4>
                        <p>To facilitate the donation of in-kind products and services, the Global Task Force launched the COVID-19 Business Response Portal, hosted by the U.S. Chamber of Commerce Foundation.</p>
                        <a href="https://usccfdonationportal.communityos.org" rel="noreferrer" target="_blank">Learn More</a>
                    </div>

                    <div className="item">
                        <h3>02</h3>
                        <h4>India Resources</h4>
                        <p>More information and resources for business as it relates to the pandemic in India.  </p>
                        <a href="https://www.uschamberfoundation.org/indiaresources" rel="noreferrer" target="_blank">Learn More</a>
                    </div>

                    <div className="item">
                        <h3>03</h3>
                        <h4>Our World in Data</h4>
                        <p>Global statistics and research on the Coronavirus Pandemic (COVID-19).  Our World in Data is about research and data to make progress against the world’s largest problems. </p>
                        <a href="https://ourworldindata.org/coronavirus-data" rel="noreferrer" target="_blank">Learn More</a>
                    </div>

                    <div className="item">
                        <h3>04</h3>
                        <h4>Understanding Our Progress Against Covid-19</h4>
                        <p>Interactive visualizations based on publicly available data that help provide full transparency into COVID-19 trends around the world. </p>
                        <a href="https://www.microsoft.com/en-us/ai/ai-for-health-covid-data" rel="noreferrer" target="_blank">Learn More</a>
                    </div>

                    <div className="item">
                        <h3>05</h3>
                        <h4>Indonesia Resources</h4>
                        <p>More information and resources for business as it relates to the pandemic in Indonesia.</p>
                        <a href="https://www.uschamberfoundation.org/community-resilience-and-disaster-response/resources-indonesias-covid-19-crisis" rel="noreferrer" target="_blank">Learn More</a>
                    </div>

                    <div className="item">
                        <h3>06</h3>
                        <h4>COUNTRY-SPECIFIC RESOURCES</h4>
                        <p>Factsheets on country-specific COVID19 response and priorities.</p>
                        <Link to="/resources/countryspecific">click here</Link>
                        <p></p>
                    </div>
                </div>
            </div>

            <div className="videos">
                <h2>Luminary Voices with Dr. Piot</h2>

                <div className="item">
                    <div className="thumbnail">
                        <a href="https://youtu.be/jzAIGa98dTM" rel="noreferrer" target="_blank">
                            <div className="playBtn"><img src="/photos/videos/video_play_icon.png"/></div>
                        </a>
                        <img src="/photos/videos/thumb_india.png" alt="Luminary Voices" />
                    </div>
                    <div className="info">
                        <h3>India</h3>
                        <h4>Luminary Voices with Dr. Piot: India surge</h4>
                        <p>Dr. Piot is joined by Accenture leaders in India to discuss the surge of COVID-19 cases in India.</p>
                        <a href="https://youtu.be/jzAIGa98dTM" rel="noreferrer" target="_blank">Watch Video</a>
                    </div>
                </div>

                <div className="item">
                    <div className="thumbnail">
                        <a href="https://youtu.be/cAYjfyQg-KA" rel="noreferrer" target="_blank">
                            <div className="playBtn"><img src="/photos/videos/video_play_icon.png"/></div>
                        </a>
                        <img src="/photos/videos/thumb_pt1.png" alt="Luminary Voices" />
                    </div>
                    <div className="info">
                        <h3>India</h3>
                        <h4>Luminary Voices with Dr. Piot: Episode 1</h4>
                        <p>Dr. Piot discusses the facts and fictions of COVID-19 with Arlin Pedrick, our Chief Security Officer.</p>
                        <a href="https://youtu.be/cAYjfyQg-KA" rel="noreferrer" target="_blank">Watch Video</a>
                    </div>
                </div>

                <div className="item">
                    <div className="thumbnail">
                    <a href="https://youtu.be/q7-dIQjgzP8" rel="noreferrer" target="_blank">
                            <div className="playBtn"><img src="/photos/videos/video_play_icon.png"/></div>
                        </a>
                        <img src="/photos/videos/thumb_pt2.png" alt="Luminary Voices" />
                    </div>
                    <div className="info">
                        <h3>India</h3>
                        <h4>Luminary Voices with Dr. Piot: Episode 2</h4>
                        <p>Dr. Piot is joined by Accenture leaders in India to discuss the surge of COVID-19 cases in India.We asked Dr. Piot to share his view on recent COVID-19 headlines—and he answers questions from our people about: vaccine risk to women, testing, boosters, variants, mRNA, and variants and children.</p>
                        <a href="https://youtu.be/q7-dIQjgzP8" rel="noreferrer" target="_blank">Watch Video</a>
                    </div>
                </div>
                
            </div>

            <div className="indent">
                <h2>Frequently Asked Questions</h2>
            </div>

            <div className="faqs">
                <h4>How can I personally support these efforts / contribute?</h4>
                <p>The U.S. Chamber of Commerce Foundation’s Resources for India’s COVID-19 Crisis page also includes a vetted list of nonprofit organizations who can accept individual donations to support their humanitarian work. To see the latest list, please <a href="https://www.uschamberfoundation.org/indiaresources#cash-donations" target="_blank" rel="noreferrer">click here</a>.</p>
            
                <h4>Is the Global Task Force only supporting COVID-19 response efforts in India? </h4>
                <p>No! While the Global Task Force’s initial efforts are on the pressing need in India, additional working groups will be formed to address COVID-19 surges in other countries. To help with efforts focused on particular countries, additional business groups will be involved. If you would like to discuss opportunities to partner with the Global Task Force on these efforts, please <Link to="/getinvolved">click here</Link>.</p>
            
                <h4>How is the Global Task Force working with the U.S. and foreign governments? </h4>
                <p>The Global Task Force, through its Steering Committee, is working closely with the White House, U.S. State Department and U.S. Agency for International Development to coordinate corporate donations. The Task Force is also hosting regular CEO-level briefings for Global Task Force members with senior U.S. government officials, as well as broader briefings for the business community.</p>
                <p>As part of its India mobilization efforts, the Global Task Force is coordinating closely with the government of India on both its ventilator and oxygen concentrator initiatives to identify healthcare facilities where these supplies are critically needed, as well as to facilitate efforts by the broader business community to provide supplies on the Government of India’s priority needs list.</p>
            
                <h4>Why was the task force established?</h4>
                <p>Over a year after the pandemic first emerged, we continue to see significant increases in cases and deaths in certain parts of the world. While vaccines are becoming more available, the pace of the virus threatens to overwhelm hospital and intensive care capacity in a number of developing countries where vaccination efforts have only just begun. Urgent action is needed.</p>
                <p>In the past several weeks, US based-companies have come together to provide immediate assistance to those suffering in India – donating critical medical supplies including ventilators and oxygen concentrators.</p>
                <p>But more needs to be done.  We need a mechanism for the private sector to coordinate private assistance – in close coordination with governments and non-profit organizations. A newly formed Global Task Force on Pandemic Response will serve as this ongoing mechanism.</p>
            
            </div>
        </div>
    )
}

export default ResourceContent;