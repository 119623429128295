import {FunctionComponent} from "react";
import Footer from "../../components/Footer";
import HeaderNav from "../../components/HeaderNav";
import PressReleaseContent3 from "../../components/PressReleaseContent3";

const PressRelease3: FunctionComponent = () => {
    return (
        <div className="page-content">
            <HeaderNav
                lightMode={true} />
            <PressReleaseContent3 />
            <Footer />
        </div>
    )
}

export default PressRelease3;