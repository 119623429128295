import { FunctionComponent} from "react";
import Footer from "../../components/Footer";
import HeaderNav from "../../components/HeaderNav";
import Header from "../../components/Header";
import GetInvolvedContent from "../../components/GetInvolvedContent";

const GetInvolved: FunctionComponent = () => {
    return (
        <div className="page-content">
            <HeaderNav lightMode={true} />
            <Header title="Get Involved" headerImg="getinvolved.jpg"/>
            <GetInvolvedContent />    
            <Footer />
        </div>
    )
}

export default GetInvolved;