import {FunctionComponent} from "react";
import { Button } from "../../atoms";
import { BUTTON_PRIMARY_BLUE } from "../../atoms/colors";

const PromoRow:FunctionComponent = () => {
    return (
        <div className="promo-row">
            <div className="promo about">
                <h3>About Us</h3>
                <h4>The Global Task Force is a unified effort for businesses to mobilize and deliver resources to relieve surges of COVID-19 around the globe. The task force was launched in May 2021 to support COVID-19 response amid rising case counts in India and around the globe.</h4>
                <Button
                    displayText="Read More"
                    buttonClass={BUTTON_PRIMARY_BLUE}
                    target="/about" />
            </div>
            {/* <div className="promo pandemic">
                <h3>Global Pandemic</h3>
                <h4>Coming to an understanding of where we need to help.</h4>
                <Button
                    displayText="Read More"
                    buttonClass={BUTTON_PRIMARY_BLUE}
                    target="/globalpandemic" />
            </div> */}
        </div>
    )
}

export default PromoRow;