import { FunctionComponent} from "react";
import AboutPartnerships from "../../components/AboutPartnerships";
import Footer from "../../components/Footer";
import Partner from "../../components/Partners";
import HeaderNav from "../../components/HeaderNav";
import Header from "../../components/Header";

const AboutPage: FunctionComponent = () => {
    return (
        <div className="page-content">
            <HeaderNav lightMode={true} />
            <Header title="Learn About Our Partnership" headerImg="partnerships.jpg"/>
            <AboutPartnerships />
            <Partner />
            <Footer />
        </div>
    )
}

export default AboutPage;