import {FunctionComponent} from "react";
import { Link } from "react-router-dom";

const NewsList: FunctionComponent = () => {

    const newsItems = [
        {
            date: 'Sep 16, 2021',
            imageUrl : '/photos/news/news.jpg',
            category: 'World',
            title: 'Global Task Force on Pandemic Response Provides Critical Support to Southeast Asia’s Diagnostic Capacity in its Battle Against COVID-19',
            lead: 'The Global Task Force on Pandemic Response (GTF), a public-private partnership of major U.S. companies organized by the U.S. Chamber of Commerce with support from Business Roundtable',
            link: '/pressrelease3'
    
        },
        {
            date: 'Aug 5, 2021',
            imageUrl : '/photos/news/news.jpg',
            category: 'World',
            title: 'Global Task Force on Pandemic Response Mobilizes Pandemic Relief in Indonesia and Southeast Asia, with Pledges of Aid from U.S. Businesses',
            lead: 'The Global Task Force on Pandemic Response (GTF) today announced plans to mobilize in support COVID-19 pandemic relief and response in Indonesia and Southeast Asia',
            link: '/pressrelease2'
    
        },
        {
        date: 'May 5, 2021',
        imageUrl : '/photos/news/news.jpg',
        category: 'World',
        title: 'Global Task Force on Pandemic Response Launched by Leading Companies and Business Associations to Address Urgent COVID-19 Surges ',
        lead: 'Public-private partnership will provide immediate assistance to India and will assist in coordinating relief to respond to COVID-19 surges.  ',
        link: '/pressrelease'

    }];

    return (
        <div className="inner-content">
            <h2>The latest information about our efforts and future needs.</h2>
            <div className="news-items">
                {newsItems.map((currentItem, index) => {
                    return (
                        <div key={index} className="item">
                            <div className="photo">
                            <div className="date">{currentItem.date}</div>
                            <Link to={currentItem.link}><img src={currentItem.imageUrl} alt={currentItem.title} /></Link>
                            </div>
                            <div className="info">
                                <h4>{currentItem.category}</h4>
                                <h5><Link to={currentItem.link}>{currentItem.title}</Link></h5>
                                <p>{currentItem.lead}</p>
                                <Link to={currentItem.link}>Read More</Link>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default NewsList;