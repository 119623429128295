import {FunctionComponent} from "react";
import { Link } from "react-router-dom";

const ImpactContent: FunctionComponent = () => {
    return (
        <div className="impact-content">
                <div className="page-label">COVID-19</div>

                <h5>India Mobilization</h5>
                <div className="two-cols">
                    <div className="col-1">
                        <p>In April 2021, amid rising COVID-19 numbers in India, the GTF was formed to help provide relief during the height of the pandemic. In total, the GTF mobilized over $46 million to assist India in its COVID-19 response.</p>
                        <h5>1,000 Ventilators</h5>
                        <p>The Task Force purchased and delivered 1,000 Medtronic ventilators, procured by the U.S. Chamber of Commerce Foundation, to support India's pressing public health needs with the first ventilators arriving in India on May 5.</p>
                        <p>Ventilators play a significant role in the management of patients with COVID-19 and other severe respiratory illness, who require assistance because they cannot breathe effectively.</p>
                        <p>Placing a patient on a ventilator allows the lungs to rest and recover while the ventilator performs the functions of supplying oxygen and simulating the actions of breathing.  To read more, <Link to="/pdf/GTF_FactSheet_Ventilators.pdf" target="_blank">click here.</Link></p>
                    </div>
                    <div className="col-1">
                        <img src="/photos/india_ventilator_map.png" alt="Ventilator Map"/>
                    </div>
                </div>
                

                <h5>30,000+ Oxygen Concentrators</h5>
                <p>In late April, the Global Task Force funded and delivered over 30,000 oxygen concentrators, provided by Deloitte with critical logistical support from FedEx.</p>
                <p>The lack of consistent oxygen supply at healthcare facilities has become a central impediment to medical care in India.</p>
                <p>Through the use of oxygen concentrators, a portable alternative to massive oxygen cylinders, many of these life-threatening constraints can be mitigated. To read more, <Link to="/pdf/GTF_FactSheet_Oxygen.pdf" target="_blank">click here</Link>.</p>

                <h5>Chief Human Resource Officer Support</h5>
                <p>This collaborative network of more than 100 global human resources leaders provides a platform for CHROs to share ideas and practical information intended to expedite getting help to their people in India. <a href="/pdf/GTF_FactSheet_CHRO.pdf" target="_blank" rel="noreferrer">Learn more</a> about the CHRO India Action Group.</p>
            
                <h5>Indonesia and Southeast Asia Mobilization</h5>
                <p>In early August 2021, the GTF announced a mobilization for COVID-19 relief in Indonesia and Southeast Asia. The GTF has been in close contact with both the Indonesian and U.S. governments to identify critical needs and assess where private sector support can be most impactful. In Indonesia, the GTF will focus its efforts in two areas – oxygen and diagnostics.</p>
                <p>In the area of diagnostics, the GTF is strengthening diagnostic capacity in the region by procuring high throughput PCR testing systems, genomic sequencing equipment to identify variants, and rapid antigen testing that can be easily deployed across a range of challenging geographies.</p>

                <h6>The GTF will continue to monitor the pandemic across the globe and is open to additional country and/or region suggestions as needs arise.</h6>
            </div>
    )
}

export default ImpactContent;