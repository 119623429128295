import { FunctionComponent} from "react";
import { Link } from "react-router-dom";

const GetInvolvedContent: FunctionComponent = () => {

    return (
        <div className="get-involved">
            <div className="indent">
                <div className="businesses">
                    <h2>Businesses</h2>
                    <p>The Global Task Force on Pandemic Response invites all businesses to join. There are 2 ways that businesses can support the effort –providing in-kind donations and/or joining the GTF.</p>
                
                    <h4>In-Kind Donations</h4>
                    <p>In response to the COVID-19 crisis in Indonesia and across Southeast Asia, the Global Task Force on Pandemic Response (GTF) has mobilized to deliver critically needed medical supplies. In partnership with the Government of Indonesia and GTF members and partners, we are working to identify and distribute medical equipment, supplies and other resources needed for pandemic response.</p>
                    <p>See below for a regularly updated list of medical supplies needed by Indonesian healthcare facilities in order of priority, as identified by the Indonesian government. If your company can supply any of these products or provide transportation and logistics assistance, please submit donation information via the U.S. Chamber of Commerce Foundation’s <a href="https://usccfdonationportal.communityos.org/" target="_blank" rel="noreferrer">Donation Portal</a>.</p>
                    <p><b>Please note:</b> The Global Task Force cannot guarantee that all offers will be accepted by foreign governments at this time. Please <a href="https://www.uschamberfoundation.org/community-resilience-and-disaster-response/resources-indonesias-covid-19-crisis" target="_blank" rel="noreferrer">click here</a> to see a regularly updated list of items that have been requested.</p>

                    <h4>Joining the Global Task Force</h4>
                    <p>The GTF comprises of major US companies, non-profits, and other organizations that are committed to supporting the global COVID-19 response. For a list of organizations within the GTF, <Link to="/about">click here</Link>. The Global Task Force on Pandemic Response invites all businesses to join.</p>
                    <p>Please use the form below to submit an inquiry.</p>

                    <iframe src="https://uschamber.tfaforms.net/f/GlobalTaskForcePandemic" height="1200" width="800" frameBorder="0"></iframe>
                    <script src="//tfaforms.com/js/iframe_resize_helper.js"></script>
                </div>  
            </div>
            <div className="indent">
                <div className="individuals">
                    <h2>Individuals</h2>
                    <p>If you or your business would like to support COVID-19 relief efforts in Indonesia, consider making a donation to one of the following organizations, recommended by members of the Global Task Force: </p>
                     <ul>
                            <li><a href="https://act.id/tentang/sejarah" target="_blank" rel="noreferrer">ACT Indonesia</a></li> 
                            <li><a href="https://www.benihbaik.com/" target="_blank" rel="noreferrer">Benih Baik (collaborated with Grab)</a></li>
                            <li><a href="http://www.doctorshare.org" target="_blank" rel="noreferrer">Doctor SHARE Foundation</a></li>
                            <li><a href="https://www.gotocompany.com/" target="_blank" rel="noreferrer">GoTo Company</a></li>
                            <li><a href="https://jliflc.com/organizations/humanitarian-forum-indonesia/" target="_blank" rel="noreferrer">Humanitarian Forum Indonesia (HFI)</a></li>
                            <li><a href="https://www.devex.com/organizations/indonesia-business-links-ibl-46600" target="_blank" rel="noreferrer">Indonesia Business Links (IBL) Foundation</a></li>
                            <li><a href="https://indorelawan.org/" target="_blank" rel="noreferrer">IndoRelawan</a></li>
                            <li><a href="https://www.ifrc.org/en/what-we-do/where-we-work/asia-pacific/indonesian-red-cross-society/" target="_blank" rel="noreferrer">International Federation of Red Cross and Red Crescent Societies<sup>1</sup></a></li>
                            <li><a href="https://kitabisa.com/" target="_blank" rel="noreferrer">KitaBisa</a></li> 
                            <li><a href="https://lkpbnu.org/" target="_blank" rel="noreferrer">Lembaga Kesehatan Nahdlatul Ulama (LKNU)</a></li>  
                            <li><a href="https://www.mercycorps.org/contact-us" target="_blank" rel="noreferrer">Mercy Corps<sup>1</sup></a></li>
                            <li><a href="https://www.savethechildren.org/us/where-we-work/indonesia" target="_blank" rel="noreferrer" >Save The Children<sup>1</sup></a></li>
                            <li><a href="https://www.worldvision.org" target="_blank" rel="noreferrer">World Vision<sup>1</sup></a></li>
                            <li><a href="https://www.anakbangsabisa.org/" target="_blank" rel="noreferrer">Yayasan Anak Bangsa Bisa (YABB) (Gojek Foundation)</a></li>
                            <li><a href="http://idepfoundation.org/en/" target="_blank" rel="noreferrer">Yayasan IDEP Selaras Alam</a></li>
                            <li><a href="https://yki4tbc.org/tentang-kami/yayasan-kncv-indonesia.html" target="_blank" rel="noreferrer">Yayasan KNCV Indonesia</a></li>
                            <li><a href="http://www.yumindonesia.org/" target="_blank" rel="noreferrer">YAYASAN Usaha Mulia (YUM)</a></li>
                            <li><a href="https://www.ycabfoundation.org/" target="_blank" rel="noreferrer">YCAB Foundation</a></li>
                        </ul>
                     <p>
                        1 Represents organizations that are US based 501c3 charities or affiliates <br/>
                        *USCC/USCC Foundation has not independently vetted all of these organizations 
                    </p>
                </div>
            </div>
        </div>
    )
}

export default GetInvolvedContent;