import {FunctionComponent} from "react";
import { LogoType, LOGO_COLOR } from "../colors";

type LogoProps = {
    logoType?: LogoType;
}

const Logo: FunctionComponent<LogoProps> = ({
    logoType
}) => {

    const file = logoType ?? LOGO_COLOR;

    return (
        <div className="logo-container">
            <img src={`/logos/${file}`} alt="Global Taskforce on Pandemic Response Logo" />
        </div>
    )
}

export default Logo;