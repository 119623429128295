import { FunctionComponent} from "react";
import Footer from "../../components/Footer";
import HeaderNav from "../../components/HeaderNav";
import Header from "../../components/Header";
import KeyMetrics from "../../components/KeyMetrics";

const Vaccinations: FunctionComponent = () => {
    return (
        <div className="page-content">
            <HeaderNav lightMode={true} />
            <Header title="Global Pandemic" headerImg="globalpandemic.jpg"/>
            <KeyMetrics onpage="vaccinations" />
            <Footer />
        </div>
    )
}

export default Vaccinations;