import {FunctionComponent} from "react";
import Footer from "../../components/Footer";
import HeaderNav from "../../components/HeaderNav";
import PressReleaseContent2 from "../../components/PressReleaseContent2";

const PressRelease2: FunctionComponent = () => {
    return (
        <div className="page-content">
            <HeaderNav
                lightMode={true} />
            <PressReleaseContent2 />
            <Footer />
        </div>
    )
}

export default PressRelease2;