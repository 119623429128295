import { FunctionComponent} from "react";

const CountryResourceContent: FunctionComponent = () => {
    return (
        <>
            <div className="about-partnerships extra-padding no-bottom-padding">
                <p>Private sector companies have a great deal of expertise and can often offer unique support to help solve complex challenges or issues. In partnership with the U.S. Agency for International Development (USAID), the US Chamber of Commerce has selected 15 countries with varying COVID-19 response needs and priorities. The following informational factsheets represent these country-specific COVID-19 response needs and priorities that may benefit from private sector support. For country-specific questions, or to coordinate assistance, please contact the USAID/Mission contacts listed in each profile with copy to <a href="mailto:" title="covid-pse@usaid.gov">covid-pse@usaid.gov</a>: </p>
            
            </div>
            
            <div className="partners">
                <ul>
                    <li><a href="/pdf/countryprofiles/Angola_Country_Profile_PSE_Opportunities.pdf" rel="noreferrer" target="_blank">Angola</a></li>
                    <li><a href="/pdf/countryprofiles/Bangladesh_Country_Profile_PSE_Opportunities.pdf" rel="noreferrer" target="_blank">Bangladesh</a></li>
                    <li><a href="/pdf/countryprofiles/Colombia_Country_Profile_PSE_Opportunities.pdf" rel="noreferrer" target="_blank">Colombia</a></li>
                    <li><a href="/pdf/countryprofiles/Guatemala_Country_Profile_PSE_Opportunities.pdf" rel="noreferrer" target="_blank">Guatemala</a></li>
                    <li><a href="/pdf/countryprofiles/Honduras_Country_Profile_PSE_Opportunities.pdf" rel="noreferrer" target="_blank">Honduras</a></li>
                    <li><a href="/pdf/countryprofiles/Lesotho_Country_Profile_PSE_Opportunities.pdf" rel="noreferrer" target="_blank">Lesotho</a></li>
                    <li><a href="/pdf/countryprofiles/Mexico_Country_Profile_PSE_Opportunities.pdf" rel="noreferrer" target="_blank">Mexico</a></li>
                    <li><a href="/pdf/countryprofiles/Peru_Country_Profile_PSE_Opportunities.pdf" rel="noreferrer" target="_blank">Peru</a></li>
                    <li><a href="/pdf/countryprofiles/Philippines_Country_Profile_PSE_Opportunities.pdf" rel="noreferrer" target="_blank">Philippines</a></li>
                    <li><a href="/pdf/countryprofiles/South_Africa_Country_Profile_PSE_Opportunities.pdf" rel="noreferrer" target="_blank">South Africa</a></li>
                    <li><a href="/pdf/countryprofiles/Sri_Lanka_Country_Profile_PSE_Opportunities.pdf" rel="noreferrer" target="_blank">Sri Lanka</a></li>
                    <li><a href="/pdf/countryprofiles/Tanzania_Country_Profile_PSE_Opportunities.pdf" rel="noreferrer" target="_blank">Tanzania</a></li>
                    <li><a href="/pdf/countryprofiles/Thailand_Country_Profile_PSE_Opportunities.pdf" rel="noreferrer" target="_blank">Thailand</a></li>
                    <li><a href="/pdf/countryprofiles/Vietnam_Country_Profile_PSE_Opportunities.pdf" rel="noreferrer" target="_blank">Vietnam</a></li>
                    <li><a href="/pdf/countryprofiles/Zambia_Country_Profile_PSE_Opportunities.pdf" rel="noreferrer" target="_blank">Zambia</a></li>
                </ul>
            </div>
        </>
    )
}

export default CountryResourceContent;