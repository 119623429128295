import { FunctionComponent} from "react";
import Footer from "../../components/Footer";
import HeaderNav from "../../components/HeaderNav";
import ResourceContent from "../../components/ResourceContent";
import Header from "../../components/Header";

const Resources: FunctionComponent = () => {
    return (
        <div className="page-content">
        <HeaderNav lightMode={true} />
        <Header title="Resources" headerImg="resources.jpg"/>
        <ResourceContent />
        <Footer />
    </div>
    )
}

export default Resources;