import { FunctionComponent, useState } from "react";
import { Link, useLocation} from "react-router-dom";
import { Button, Logo } from "../../atoms";
import { LogoType, LOGO_COLOR, LOGO_WHITE, BUTTON_WHITE, ButtonClass } from "../../atoms/colors";
type HeaderNavProps = {
    lightMode?: boolean
    logoType?: LogoType
    ButtonClass?: ButtonClass

}
const HeaderNav:FunctionComponent<HeaderNavProps> = ({
    lightMode,
    logoType,
    ButtonClass
}) => {

    const location = useLocation();

    const [mobileNav, setMobileNav] = useState(false);

    const path = location.pathname ?? ""

    console.log(location.pathname);

    return (
        <div className={"fixed-header" + (lightMode ? " light" : "")}>
            <div className="mobile-nav" onClick={() => {
                setMobileNav(!mobileNav);
            }}>
                <svg className="icon icon-grid" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 17 17">
                    <path d="M1.5 14c.8 0 1.5.7 1.5 1.5S2.3 17 1.5 17 0 16.3 0 15.5.7 14 1.5 14zm14 0c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5-1.5-.7-1.5-1.5.7-1.5 1.5-1.5zm-7 0c.8 0 1.5.7 1.5 1.5S9.3 17 8.5 17 7 16.3 7 15.5 7.7 14 8.5 14zm-7-7C2.3 7 3 7.7 3 8.5S2.3 10 1.5 10 0 9.3 0 8.5.7 7 1.5 7zm14 0c.8 0 1.5.7 1.5 1.5s-.7 1.5-1.5 1.5S14 9.3 14 8.5 14.7 7 15.5 7zm-7 0c.8 0 1.5.7 1.5 1.5S9.3 10 8.5 10 7 9.3 7 8.5 7.7 7 8.5 7zm-7-7C2.3 0 3 .7 3 1.5S2.3 3 1.5 3 0 2.3 0 1.5.7 0 1.5 0zm14 0c.8 0 1.5.7 1.5 1.5S16.3 3 15.5 3 14 2.3 14 1.5 14.7 0 15.5 0zm-7 0c.8 0 1.5.7 1.5 1.5S9.3 3 8.5 3 7 2.3 7 1.5 7.7 0 8.5 0z"></path>
                </svg>
            </div>
            <div className="logo">
                <Logo 
                    logoType={logoType ? logoType : lightMode ? LOGO_COLOR : LOGO_WHITE} />
            </div>
            <div className={`navigation ${mobileNav ? 'nav-open' : 'nav-closed'}`}>
                <div className={"link" + (path.indexOf("/home")> -1 || path === "" ? " selected" : "")}><Link to="/home">Home</Link></div>
                <div className={"link" + (path.indexOf("/about")> -1 ? " selected" : "")}><Link to="/about">About</Link></div>
                <div className={"link" + (path.indexOf("/impact")> -1 ? " selected" : "")}><Link to="/impact">Impact</Link></div>
                {/* <div className={"link" + (path.indexOf("/globalpandemic")> -1 ? " selected" : "")}><Link to="/globalpandemic">Global Pandemic</Link></div> */}
                <div className={"link" + (path.indexOf("/newsroom")> -1 ? " selected" : "")}><Link to="/newsroom">Newsroom</Link></div>
                <div className={"link" + (path.indexOf("/resources")> -1 ? " selected" : "")}><Link to="/resources">Resources</Link></div>
                <div className="link close">
                    <Button
                        displayText="Get Involved" 
                        buttonClass={BUTTON_WHITE}
                        target="/getinvolved"
                    />
                </div>
                <div className="link close" onClick={() => {
                     setMobileNav(!mobileNav);
                }}>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14">
                        <path d="M13.4.6a1 1 0 0 1 0 1.4l-5 5 5 5c.4.4.4.9.1 1.3v.1a1 1 0 0 1-1.4 0l-5-5-5 5c-.4.4-.9.4-1.3.1H.6a1 1 0 0 1 0-1.4l5-5-5-5C.3 1.7.2 1.1.6.7V.6A1 1 0 0 1 2 .6l5 5 5-5c.4-.4.9-.4 1.3-.1h.1z"></path>
                    </svg>
                </div>
            </div>
            <div className="cta">
                <Button
                    displayText="Get Involved" 
                    buttonClass={BUTTON_WHITE}
                    target="/getinvolved"
                />
            </div>
        </div>
    )
}

export default HeaderNav;