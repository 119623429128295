import {FunctionComponent} from "react";

type HeaderProps = {
    title: string;
    headerImg: string;
}

const Header:FunctionComponent<HeaderProps> = ({
    title,
    headerImg
}) => {
    
    return (
        <div className="header" style={{
            backgroundImage: "url(/headers/" + headerImg +")"
        }}>
            <h1>{title}</h1>
        </div>
    );
}

export default Header;