import { FunctionComponent} from "react";
import { ButtonClass } from "../colors";
import {useHistory} from "react-router-dom"

type ButtonProps = {
    displayText: string;
    target: string;
    buttonClass: ButtonClass
}


const Button: FunctionComponent<ButtonProps> = ({
    displayText,
    buttonClass,
    target
}) => {

    const history = useHistory();

    const takeAction = () => {
        if(target !== undefined  && target.indexOf("https://") === -1){
            history.push(target);
        }else{
            window.open(target);
        }
    }

    return (
        <div
            onClick={takeAction}
            className={`button ${buttonClass}`}
            >
            {displayText}
        </div>
    );
}

export default Button;