import { FunctionComponent } from "react";
import {
  Route,
  Switch,
} from "react-router-dom";

import Home from "./pages/Home";
import About from "./pages/About";
import GlobalPandemic from "./pages/GlobalPandemic";
import Newsroom from "./pages/Newsroom";
import GetInvolved from "./pages/GetInvolved";
import Impact from "./pages/Impact";
import Resources from "./pages/Resources";
import CountrySpecificResources from "./pages/CountrySpecificResources";
import PressRelease from "./pages/PressRelease";
import PressRelease2 from "./pages/PressRelease2";
import PressRelease3 from "./pages/PressRelease3";
import Vaccinations from "./pages/Vaccinations";
import Testing from "./pages/Testing";
import SpreadAnalysis from "./pages/SpreadAnalysis";

const routes = [
  {
    path: "/home",
    exact: true,
    component: Home,
  },
  {
    path: "/about",
    exact: true,
    component: About,
  },
  {
    path: "/impact",
    exact: true,
    component: Impact,
  },
  {
    path: "/globalpandemic",
    exact: true,
    component: GlobalPandemic,
  },
  {
    path: "/newsroom",
    exact: true,
    component: Newsroom,
  },
  {
    path: "/resources",
    exact: true,
    component: Resources,
  },
  {
    path: "/resources/countryspecific",
    exact: true,
    component: CountrySpecificResources,
  },
  {
    path: "/getinvolved",
    exact: true,
    component: GetInvolved,
  },
  {
    path: "/pressrelease",
    exact: true,
    component: PressRelease,
  },
  {
    path: "/pressrelease2",
    exact: true,
    component: PressRelease2,
  },
  {
    path: "/pressrelease3",
    exact: true,
    component: PressRelease3,
  },
  {
    path: "/vaccinations",
    exact: true,
    component: Vaccinations,
  },
  {
    path: "/spreadanalysis",
    exact: true,
    component: SpreadAnalysis,
  },
  {
    path: "/testing",
    exact: true,
    component: Testing,
  },
];

const App: FunctionComponent =  () => {
  return (
    <Switch>
        {routes.map((currentRoute) => {
          return (
            <Route
              key={currentRoute.path}
              exact={currentRoute.exact}
              path={currentRoute.path}
              component={currentRoute.component}
            />
          );
        })}
        <Route component={Home} />
      </Switch>
  );
}

export default App;
