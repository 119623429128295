import { FunctionComponent} from "react";

const AboutPartnerships:FunctionComponent = () => {
    return (
        <div className="about-partnerships extra-padding">
            <h2>We've come together to provide a unified platform for businesses to mobilize and deliver resources to assist COVID-19 efforts in areas of the highest need around the world.</h2>
            <p>The Global Task Force was launched in May 2021 to support COVID-19 response amid rising case counts in India and around the globe.</p>
            <p>The Global Task Force is guided by a steering committee comprised of CEOs and C-Suite leaders from leading U.S. companies, business organizations and non-profits. The U.S. Chamber of Commerce, the U.S. Chamber of Commerce Foundation and Business Roundtable provide support and facilitation, in collaborationwith country and region-specific partners. </p>
        </div>
    )
}

export default AboutPartnerships;