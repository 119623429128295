import { FunctionComponent} from "react";
import Footer from "../../components/Footer";
import HeaderNav from "../../components/HeaderNav";
import CountryResourceContent from "../../components/CountryResourceContent";
import Header from "../../components/Header";

const CountrySpecificResources: FunctionComponent = () => {
    return (
        <div className="page-content">
        <HeaderNav lightMode={true} />
        <Header title="Country-Specific Resources" headerImg="resources.jpg"/>
        <CountryResourceContent />
        <Footer />
    </div>
    )
}

export default CountrySpecificResources;