import { FunctionComponent } from "react";

const Partner:FunctionComponent = () => {
    
    const partners = [{
        logo: "/photos/companies/logo_accenture.png",
        url: "1",
        name: "Accenture"
    },
    {
        logo: "/photos/companies/logo_adobe.png",
        url: "1",
        name: "Adobe"
    },
    {
        logo: "/photos/companies/logo_advamed.png",
        url: "1",
        name: "AdvaMed"
    },
    {
        logo: "/photos/companies/logo_albright.png",
        url: "1",
        name: "Albright"
    },
    {
        logo: "/photos/companies/logo_alphabet.png",
        url: "1",
        name: "Alphabet"
    },
    {
        logo: "/photos/companies/logo_amazon.png",
        url: "1",
        name: "Amazon"
    },
    {
        logo: "/photos/companies/logo_americanredcross.png",
        url: "1",
        name: "American Red Cross"
    },
    {
        logo: "/photos/companies/logo_apple.png",
        url: "1",
        name: "Apple"
    },
    {
        logo: "/photos/companies/logo_bankofamerica.png",
        url: "1",
        name: "Bank of America"
    },
    {
        logo: "/photos/companies/logo_gatesfoundation.png",
        url: "1",
        name: "Gates Foundation"
    },
    {
        logo: "/photos/companies/logo_bostonconsultinggroup.png",
        url: "1",
        name: "Boston Consulting Group, Inc."
    },
    {
        logo: "/photos/companies/logo_br.png",
        url: "1",
        name: "Business Roundtable"
    },
    {
        logo: "/photos/companies/logo_cardinalhealth.png",
        url: "1",
        name: "Cardinal Health"
    },
    {
        logo: "/photos/companies/logo_cognizant.png",
        url: "1",
        name: "Cognizant"
    },
    {
        logo: "/photos/companies/logo_deloitte.png",
        url: "1",
        name: "Deloitte"
    },
    {
        logo: "/photos/companies/logo_dhl.png",
        url: "1",
        name: "DHL"
    },
    {
        logo: "/photos/companies/logo_dow.png",
        url: "1",
        name: "DOW"
    },
    {
        logo: "/photos/companies/logo_emerson.png",
        url: "1",
        name: "Emerson"
    },
    {
        logo: "/photos/companies/logo_fedex.png",
        url: "1",
        name: "Fedex"
    },
    {
        logo: "/photos/companies/logo_freeportmcmoran.png",
        url: "1",
        name: "Freeport-McMoRan"
    },
    {
        logo: "/photos/companies/logo_gap.png",
        url: "1",
        name: "Gap, Inc."
    },
    {
        logo: "/photos/companies/logo_ibm.png",
        url: "1",
        name: "IBM"
    },
    {
        logo: "/photos/companies/logo_jandj.png",
        url: "1",
        name: "Johnson & Johnson"
    },
    {
        logo: "/photos/companies/logo_mastercard.png",
        url: "1",
        name: "Mastercard"
    },
    {
        logo: "/photos/companies/logo_medtronic.png",
        url: "1",
        name: "Medtronic"
    },
    {
        logo: "/photos/companies/logo_mckinsey.png",
        url: "1",
        name: "McKinsey"
    },
    {
        logo: "/photos/companies/logo_microsoft.png",
        url: "1",
        name: "Microsoft"
    },
    {
        logo: "/photos/companies/logo_pepsico.png",
        url: "1",
        name: "Pepsico"
    },
    {
        logo: "/photos/companies/logo_sanfordhealth.png",
        url: "1",
        name: "Sanford Health"
    },
    {
        logo: "/photos/companies/logo_starr.png",
        url: "1",
        name: "Starr Insurance"
    },
    {
        logo: "/photos/companies/logo_usbank.png",
        url: "1",
        name: "U.S. Bank"
    },
    {
        logo: "/photos/companies/logo_uschamberofcommerce.png",
        url: "1",
        name: "US Chamber of Commerce"
    },
    {
        logo: "/photos/companies/logo_usindiastrategicpartnershipforum.png",
        url: "1",
        name: "US India Strategic Partnership Forum"
    },
    {
        logo: "/photos/companies/logo_unitedairlines.png",
        url: "1",
        name: "United Airlines"
    },
    {
        logo: "/photos/companies/logo_ups.png",
        url: "1",
        name: "UPS"
    },
    {
        logo: "/photos/companies/logo_walmart.png",
        url: "1",
        name: "Walmart"
    }];
    
    return (
        <div className="partners">
            <h4>Global Task Force Members: </h4>
            <ul>
                <li>Accenture, Julie Sweet, Chief Executive Officer *</li>
                <li>Adobe, Shantanu Narayen, Chief Executive Officer *</li>
                <li>AdvaMed, Scott Whitaker, President and Chief Executive Officer</li>
                <li>Albright Stonebridge Group, Dan Rosenthal, Managing Principal</li>
                <li>Alphabet Inc., Sundar Pichai, Chief Executive Officer *</li>
                <li>Altec Industries Inc., Lee Styslinger, Chairman and Chief Executive Officer</li>
                <li>Amazon, Andy Jassy, Chief Executive Officer *</li>
                <li>American Red Cross, Gail McGovern, President and Chief Executive Officer *</li>
                <li>American Tower, Tom Bartlett, President and Chief Executive Officer</li>
                <li>Apple Inc., Tim Cook, Chief Executive Officer *</li>
                <li>Bank of America, Brian Moynihan, Chairman of the Board and Chief Executive Officer *</li>
                <li>Bill &amp; Melinda Gates Foundation, Mark Suzman, Chief Executive Officer *</li>
                <li>Boeing, Sir Michael Arthur, Senior Vice President of Boeing and President of Boeing International</li>
                <li>Boston Consulting Group, Inc., Rich Lesser, Chief Executive Officer</li>
                <li>Business Roundtable, Joshua Bolten, President and Chief Executive Officer *</li>
                <li>Cardinal Health, Ola Snow, Chief Human Resource Officer</li>
                <li>Cognizant, Brian Humphries, Chief Executive Officer</li>
                <li>C.V. Starr &amp; Co., Inc., Maurice R. Greenberg, Chairman &amp; CEO</li>
                <li>Deloitte, Punit Renjen, Global Chief Executive Officer *</li>
                <li>DHL Express, Mike Parra, Chief Executive Officer, Americas *</li>
                <li>Dow Inc., Jim Fitterling, Chairman and Chief Executive Officer *</li>
                <li>Emerson, Lal Karsanbhai, Chief Executive Officer *</li>
                <li>FedEx Corporation, Rajesh Subramaniam, President and Chief Operating Officer *</li>
                <li>Fidelity, Arshad Sayyad, President, Fidelity India</li>
                <li>Freeport-McMoRan, Richard Adkerson, Chairman and CEO *</li>
                <li>Gap Inc., Sally Gilligan, Chief Growth Transformation Officer</li>
                <li>Interpublic Group, Andrew Bonzani, Executive Vice President and General Counsel</li>
                <li>IBM, Arvind Krishna, Chairman and Chief Executive Officer *</li>
                <li>Johnson &amp; Johnson, Alex Gorsky	, Chairman of the Board and Chief Executive Officer *</li>
                <li>Mastercard, Michael Miebach, Chief Executive Officer *</li>
                <li>Medtronic, Geoff Martha, Chairman and Chief Executive Officer *</li>
                <li>McKinsey &amp; Company, Shubham Singhal, Global Leader, Healthcare and Public &amp; Social Sector Practices</li>
                <li>Microsoft Corporation, Brad Smith, President *</li>
                <li>Nasdaq, Adena Friedman, President and Chief Executive Officer</li>
                <li>PepsiCo, Inc., Ramon Laguarta, Chairman of the Board and Chief Executive Officer *</li>
                <li>Sanford Health, Bill Gassen, President and Chief Executive Officer</li>
                <li>U.S. Bank, Andrew Cecere, Chairman, President and Chief Executive Officer</li>
                <li>U.S. Chamber of Commerce, Suzanne Clark, President and Chief Executive Officer *</li>
                <li>United Airlines, Scott Kirby, Chief Executive Officer *</li>
                <li>UPS, Carol Tomé, Chief Executive Officer *</li>
                <li>Walgreens Boots Alliance, Inc., John Stadley, President</li>
                <li>Walmart International, Judith McKenna, President and Chief Executive Officer *</li>
                <li>Zipline, Keller Rinaudo, CEO and Co-Founder</li>
            </ul>
            <div className="footnote">* Steering Committee</div>
            <div className="more-partners">
                <h4>India Mobilization: </h4>
                <ul>
                    <li>U.S. India Business Council, Nisha Biswal, President</li>
                    <li>U.S. India Strategic Partnership Forum, Dr. Mukesh Aghi, President and Chief Executive Officer</li>
                </ul>

                <h4>Southeast Asia Mobilization: </h4>
                <ul>
                    <li>U.S. Chamber Southeast Asia and Regional Economic Architecture program, Charles Freeman, Senior Vice President for Asia, U.S Chamber of Commerce</li>
                    <li>US-ASEAN Business Council, Ambassador Ted Osius, President &amp; CEO</li>
                    <li>American Indonesia Chamber of Commerce, Wayne Forrest, Executive Director</li>
                    <li>American Chamber of Commerce in Indonesia, Lin Neumann, Managing Director</li>
                </ul>
            </div>
            <div className="logo-board">
                {
                    partners.map((logo, index) => {
                        return (
                            <div key={index} className="partner">
                                <img src={logo.logo} alt={logo.name} />
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Partner;