import { FunctionComponent } from "react";
import { Link } from "react-router-dom";

const metrics = [{
    id: "globalpandemic",
    thumb: "/photos/metrics_progress_zero.png",
    title: "Progress To Zero And Rate Of Infection",
    featurebody: "These dashboards show COVID-19 cases relative to their peak case count and COVID-19's spread within the population. These metrics can demonstrate how effectively a country is combating the spread of the pandemic.",
    tryit: "You can filter by countries and toggle between measures such as confirmed cases, deaths, and a map vs chart view.",
    iframesrc: "https://app.powerbi.com/view?r=eyJrIjoiYWVhYTcxMmUtMmZjMC00YjllLTkzYjAtNGZlOWM0NWQ1YjY4IiwidCI6ImMxMzZlZWMwLWZlOTItNDVlMC1iZWFlLTQ2OTg0OTczZTIzMiIsImMiOjF9",
    iframetitle: "COVID-19 Progress to Zero",
    bottombody: "These dashboards show COVID-19 cases relative to their peak case count and COVID-19’s spread within the population. These metrics can demonstrate how effectively a country is combating the spread of the pandemic.",
    linktitle: "Explore the Progress to Zero and Rate of Infection Map",
    url: "/globalpandemic",
    openwindow: false
},
{
    id: "vaccinations",
    thumb: "/photos/metrics_vaccination.png",
    title: "Vaccinations",
    featurebody: "These dashboards display the percentage of a country’s population that has been vaccinated. These are important data points as vaccinations are essential to reducing the spread of COVID-19.",
    tryit: "You can filter by countries and toggle between views showing the population with at least one dose, the population fully vaccinated, vaccines administered by date, and a map vs chart view.",
    iframesrc: "https://app.powerbi.com/view?r=eyJrIjoiMzU5YzZjNjEtN2E3ZS00ZTg4LTllNjUtN2EyZDQ4NjhmMmU0IiwidCI6ImMxMzZlZWMwLWZlOTItNDVlMC1iZWFlLTQ2OTg0OTczZTIzMiIsImMiOjF9",
    iframetitle: "COVID-19 Vaccinations",
    bottombody: "These dashboards display the percentage of a country's population that has been vaccinated. These are important data points as vaccinations are essential to reducing the spread of COVID-19.",
    linktitle: "Explore the Vaccinations Map",
    url: "/vaccinations",
    openwindow: false
},
{
    id: "spreadanalysis",
    thumb: "/photos/metrics_spread_analysis.png",
    title: "Spread Analysis",
    featurebody: "These dashboards provide insight into the total number of COVID-19 cases and deaths. This data reveals how pervasive COVID-19 is in a country and its impact on the population.",
    tryit: "You can filter by country and toggle between measures such as cases, deaths, cases per 100,000 people, number of new cases, and a map vs chart view.",
    iframesrc: "https://app.powerbi.com/view?r=eyJrIjoiZWI0OTU0NGYtYmI1NS00YmI2LWE4ZTktMTBiZTkxNjk0MTFlIiwidCI6ImMxMzZlZWMwLWZlOTItNDVlMC1iZWFlLTQ2OTg0OTczZTIzMiIsImMiOjF9",
    iframetitle: "COVID-19 Spread Analysis",
    bottombody: "These dashboards provide insight into the total number of COVID-19 cases and deaths. This data reveals how pervasive COVID-19 is in a country and its impact on the population.",
    linktitle: "Explore the Spread Analysis Map",
    url: "/spreadanalysis",
    openwindow: false
},
{
    id: "testing",
    thumb: "/photos/metrics_testing.png",
    title: "Positivity Test Rate And Daily Testing",
    featurebody: "These dashboards chart whether a country is meeting testing and positivity rate thresholds, which support efforts to suppress the pandemic. Public health experts in the U.S. have identified a rate of infection of less than 3% daily as the threshold for safe reopening.",
    tryit: "You can filter by country and toggle between a map vs chart view.",
    iframesrc: "https://app.powerbi.com/view?r=eyJrIjoiZjA2NDIzNTItMjlkMC00Yjg1LTgxYjQtNGUzZjg1MDMwZGNlIiwidCI6ImMxMzZlZWMwLWZlOTItNDVlMC1iZWFlLTQ2OTg0OTczZTIzMiIsImMiOjF9",
    iframetitle: "COVID-19 Positivity Test Rate and Daily Testing",
    bottombody: "These dashboards chart whether a country is meeting testing and positivity rate thresholds, which support efforts to suppress the pandemic. Public health experts in the U.S. have identified a rate of infection of less than 3% daily as the threshold for safe reopening.",
    linktitle: "Explore the Positivity Test Rate And Daily Testing Map",
    url: "/testing",
    openwindow: false
}
];

type KeyMetricsProps = {
    onpage?: string
    backgroundklass?: boolean
}

const KeyMetrics:FunctionComponent<KeyMetricsProps> = ({onpage = 'globalpandemic', backgroundklass = false}) => {
    
    var featureContent = metrics[metrics.findIndex(item => item.id === onpage)];
    
    return (
        <div className="pandemic-intro">
            {
                onpage === 'globalpandemic' ?
                (
                    <div className="extra-padding">
                        <div className="page-label">COVID-19</div>
                        <h2>Where help is greatly needed</h2>
                        <p>When COVID-19 was first declared a pandemic, the World Health Organization was tracking 118,000 cases in 114 countries. Since then, COVID-19 has claimed more than 3 million lives globally. With cases rising once more, many countries are in need of immediate assistance.</p>
                    </div>
                ) : null
            }


            <div className={`${backgroundklass ? 'background extra-padding' : 'extra-padding'}`}>
                {
                    onpage !== 'globalpandemic' ? 
                        <div className="back"><Link to="/globalpandemic">Back to Global Pandemic</Link></div>
                    : null
                }

                <div className="intro">
                    <h3>{featureContent.title}</h3>
                    <p>{featureContent.featurebody}</p>
                    <p><b>Try it:</b> {featureContent.tryit}</p>
                    <div className="iframe-responsive-container">
                        <iframe
                            title={featureContent.iframetitle}
                            src={featureContent.iframesrc}
                            loading="lazy" 
                            height="550">
                        </iframe>
                    </div>
                </div>
            </div>
            <div className="extra-padding">
                <h2>Additional Key Metrics</h2>
                <div className="dashboards">
                {
                    metrics.map((item, index) => {
                        return (
                            <div key={index} className={`item ${onpage === item.id ? 'hide' : ''}`}>
                                <a href={item.url}><img src={item.thumb} alt={item.title} /></a>
                                <h4>{item.title}</h4>
                                <p>{item.bottombody}</p>
                                <a href={item.url}>{item.linktitle}</a>
                            </div>
                        )
                    })
                }
                </div>
            </div>
        </div>
    )
}

export default KeyMetrics;