import {FunctionComponent} from "react";
import Footer from "../../components/Footer";
import HeaderNav from "../../components/HeaderNav";
import Header from "../../components/Header";
import ImpactContent from "../../components/ImpactContent";

const Impact: FunctionComponent = () => {
    return (
        <div className="page-content">
            <HeaderNav lightMode={true} />
            <Header title="Impact" headerImg="impact.jpg"/>
            <ImpactContent />
            <Footer />
        </div>
    )
}

export default Impact;